<template>
    <div class="row">
        <div class="col-12 col-md-12 note-head">
            <div class="note-title">
                <h4 class="title-main">
                    <span>{{ note.name }}</span>
                    <span class="note-price" v-if="note.value === 'investigation'"> (৳{{ totalInvestigationPrice }})</span>
                </h4>
                <div v-if="from === 'group-template'">
                    <div class="input-group input-group-merge group-template">
                        <input
                            v-model="note.template_name"
                            type="text"
                            name="name" 
                            placeholder="Enter template name"
                            class="form-control"
                        />
                    </div>
                </div>
                <AsyncSelect
                    v-if="note.hasOwnProperty('service_resource')"
                    class="select-doctor"
                    placeholder="Select Doctor"
                    v-model="note.service_resource"
                    :api-service="fetchServiceList"
                    :additional-query="{ resource_type: 'human_resources' }"
                />
                <h4 class="prescription-date"><span v-if="note.date" class="note-date">{{ note.date }}</span></h4>
            </div>
            <div class="note-btn" v-if="!note.isHideAddBtn">
                <button v-if="from === 'group-template'" @click="removeNoteCard(note, index)" type="button" class="btn btn-outline-danger btn-sm me-1">
                    <i class="fas fa-trash"></i>
                </button>
                <button @click="onOpenModal(note, index)" type="button" class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
            <div class="note-btn" v-if="note.isEdited">
                <button @click="onClickToPrintRx" class="btn btn-secondary btn-sm">
                    <i class="fas fa-print"></i>
                </button>
            </div>
        </div>
        <hr>
        <div class="col-12 col-md-12 note-body">
            <div class="card">
                <div class="card-body">
                    <div :class="`${item.status === 'inactive' && 'disabled-element'}`" class="rx-body" v-for="(item, noteIndex) in note.selectedNotes" :key="noteIndex">
                        <div class="medicine">
                            <div class="medicine-name" :class="`${item.status === 'inactive' && 'text-with-divider'}`">
                                <span class="sr-no">{{ noteIndex + 1 }}. </span>
                                <span class="name">{{ item.name }}</span>
                            </div>
                            <div v-if="checkTerms(item)" @click="onRemoveNote(noteIndex, item)" class="action">
                                <i class="far fa-times-circle close"></i>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div v-for="medNote in noteObj.medicineNotes" :key="medNote.value" :class="`${col} dose`">
                                <button @click="openRxMedicineModal(medNote, noteIndex, item)" type="button" class="btn btn-sm btn-block">
                                    <span v-if="!item[medNote.primeValue]">
                                        {{ medNote.name }}
                                        <i class="fas fa-plus"></i>
                                    </span>
                                    <span v-if="item[medNote.primeValue]">
                                        {{ item[medNote.primeValue] }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 note-footer pb-2" v-if="from === 'group-template'">
            <SubmitCancelBtn
                class="text-right"
                @onSubmit="onSaveTemplate"
                :loading="loading"
                :isHideCancelBtn="true"
            />
        </div>
        <MedicineNoteModal 
            v-if="isModalOpen"
            :noteObj="noteObj"
            @onHandleMedNote="onHandleMedNote"
            @onCloseModal="onCloseModal"
        />
    </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref } from "vue"
import { useStore } from "vuex";
import MedicineNoteModal from "./MedicineNoteModal.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleHospital from '@/services/modules/hospital'
import { computed } from "vue";
import figureFormatter from '@/services/utils/figureFormatter'
import { useRoute } from "vue-router";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';

const props = defineProps({
    note: Object,
    index: Number,
    openModal: Function,
    medicineNotes: {
        type: Array,
        default: [],
    },
    from: {
        type: String,
        default: ''
    },
    onClickToPrintRx: {
        type: Function,
        default: () => {}
    },
    onSave: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['openModal', 'onClickToPrintRx', 'onSave'])
const { fetchServiceList, storeGroupTemplate, updateGroupTemplate, deleteGroupTemplate } = handleHospital();
const {commaFormat} = figureFormatter();
const store = useStore();
const route = useRoute();
const col = ref('col-3 col-md-3');
const isModalOpen = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const templateForm = ref({
    name: '',
    template_type: ''
});

const totalInvestigationPrice = computed(() => {
    if(props.note.value !== 'investigation') return 0;
    const price = props.note.selectedNotes.reduce((a, b) => a + b.price, 0);
    return commaFormat(price);
})

const noteObj = reactive({
    currentTab: 'dose',
    medicineNotes: [],
    noteIndex: -1,
    selectedNote: '',
}) 

const onClickToPrintRx = () => {
    emit('onClickToPrintRx', { rxNote: props.note, index: props.index })
}

const removeNoteCard = (note, index) => {
    if(! confirm('Are you sure to remove this template?')) return;
    if(! note.id) {
        showSuccess('Template removed successfully');
        emit('onSave');
        return;
    }
    removeGroupTemplate(note.id);
}

const removeGroupTemplate = async (id) => {
    const query = `?company_id=${route.params.companyId}`;

    await deleteGroupTemplate(query, id).then((res) => {
        if (!res.status) return showError(res.message);
        showSuccess(res.message);
        emit('onSave')
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const checkTerms = (item) => {
    if(!props.from) return true;
    else if(['doctorOrder', 'group-template'].includes(props.from) && item.status === 'active') return true;
    return false;
}

const onOpenModal = (note, index) => {
    emit('openModal', {note, index})
}

const onHandleMedNote = ({note, newNote}) => {
    const currentTab = noteObj.currentTab === 'investigation_remarks' ? 'remarks' : noteObj.currentTab;
    props.note.selectedNotes[noteObj.noteIndex][currentTab] = note.name;
    if(newNote){
        props.note.newNotesByDoctor.push(newNote);
    }
}

const openRxMedicineModal = (item, index, selectedNote) => {
    noteObj.currentTab = item.value;
    noteObj.noteIndex = index;
    noteObj.selectedNote = selectedNote;
    isModalOpen.value = true;
}

const onRemoveNote = (selectedIndex, item) => {
    if(item.isEdited){
        item.status = 'inactive'
        return;
    }
    props.note.selectedNotes.splice(selectedIndex, 1)
}

const setHideOrShowTabs = () => {
    noteObj.medicineNotes.length = 0;
    const filteredNotes = props.medicineNotes.filter(item => {
        item.primeValue = item.value
        if(item.value === 'investigation_remarks'){
            item.primeValue = 'remarks'
        }
        if (props.note.value === 'rx') {
            return item.value !== 'investigation_remarks';
        }
        return item.value === 'investigation_remarks';
    })
    noteObj.medicineNotes.push(...filteredNotes);
}

const onSaveTemplate = async () => {
    const data = formatFormData();

    const id = props.note.id || null;
    const templateAction = id ? updateGroupTemplate : storeGroupTemplate;

    if(!data.name){
        return showError('Please enter name');
    }

    if(data.notes.length === 0){
        return showError('Please add at least one item.');
    }

    loading.value = true;
    await templateAction(data, id).then((res) => {
        if (!res.status) return showError(res.message);
        showSuccess(res.message);
        emit('onSave');
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    const formattedData = {};
    formattedData.name = props.note.template_name;
    formattedData.company_id = route.params.companyId;
    formattedData.template_type = props.note.value;
    
    formattedData.notes = props.note.selectedNotes.map((item) => {
            return {
                id: item.template_id || null,
                templateable_id: item.template_id ? item.templateable_id : item.product_id,
                additional_info: {
                    dose: item.dose,
                    unit: item.unit,
                    duration: item.duration,
                    remarks: item.remarks,
                    investigation_remarks: item.investigation_remarks
                }
            }
        });

    return formattedData;
}

const onCloseModal = () => {
    isModalOpen.value = false;
}

onMounted(() => {
    if(props.note.template_name){
        templateForm.value.name = props.note.template_name;
    }
    setHideOrShowTabs()
    if(props.note.value === 'investigation'){
        col.value = 'col-12 col-md-12'
    }
})

</script>

<style scoped>
.note-btn {
    text-align: right;
    width: 11%;
}
.group-template {
    width: 180%;
}
.note-title {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.note-price {
    font-size: 14px;
}
.select-doctor {
    width: 48%;
}
.prescription-date {
    width: 34%;
}
.note-date {
    font-size: 16px;
    margin-left: 2%;
}
.rx-body .row > *{
    padding: 0 !important;
}
.dose button:hover {
    color: #7367f0;
}
.remarks button:hover {
    color: #7367f0;
}
.duration button:hover {
    color: #7367f0;
}
.rx-body {
    padding-bottom: 8px;
}
.note-input{
    position: absolute;
    width: 78%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 3%;
}
.note-body .card-body{
    padding: 0 2%;
}
.note-head {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 1%;
}
.action .close {
    color: red;
    cursor: pointer;
}
.note-body .card {
    background: #f9f9f9;
    min-height: 100px;
    margin-bottom: 1rem;
}
.medicine {
    display: flex;
    justify-content: space-between;
    margin: 7px 0;
    align-items: center;
    position: relative;
}
</style>